<template>
	<div>
		<div>
			<span>已选表头（23）</span>
			<span class="cr_999 pl20">(可拖动调整顺序)</span>
		</div>
		<div class="mt30" ref="tagRef">
			<el-tag @close="tagClose(item)" closable class="mr10 pl6 pr6" size="small" v-for="item in selectTag" :key="item.label">{{item.label}}</el-tag>
		</div>
		<el-divider class="mt50 mb50"></el-divider>
		<div class="flex_r f_ai_c">
			<span class="mr20">可选表头</span>
			<el-input class="w_200">
				<el-button slot="append" icon="el-icon-search"></el-button>
			</el-input>
		</div>
		<div class="mt40">
			<span class="cr_999">直播信息：</span>
			<el-tag
				@click="tagClick(item)"
				size="small"
				class="mr10 cur_p"
				v-for="item in liveList"
				:key="item.label"
				effect="plain">
				{{ item.label }} +
			</el-tag>
		</div>
		<div class="mt40">
			<span class="cr_999">基本信息：</span>
			<el-tag
				@click="tagClick(item)"
				size="small"
				class="mr10 cur_p"
				v-for="item in baseList"
				:key="item.label"
				effect="plain">
				{{ item.label }} +
			</el-tag>
		</div>
		<div class="mt40">
			<span class="cr_999">其他信息：</span>
			<el-tag
				@click="tagClick(item)"
				size="small"
				class="mr10 cur_p"
				v-for="item in otherList"
				:key="item.label"
				effect="plain">
				{{ item.label }} +
			</el-tag>
		</div>
		<div class="tc mt80">
			<el-button type="primary">保存</el-button>
		</div>
	</div>
</template>

<script>
import Sortable from 'sortablejs'
export default {
	data() {
		return {
			selectTag: [
				{
					label: 'l1',
					type: 'liveList'
				},
				{
					label: 'b2',
					type: 'baseList'
				}
			],
			liveList: [
				{
					label: 'l2',
					type: 'liveList'
				},
				{
					label: 'l3',
					type: 'liveList'
				}
			],
			baseList: [
				{
					label: 'b1',
					type: 'baseList'
				},
				{
					label: 'b3',
					type: 'baseList'
				}
			],
			otherList: [
				{
					label: 'o1',
					type: 'otherList'
				},
				{
					label: 'o2',
					type: 'otherList'
				}
			]
		}
	},
	mounted() {
		const $tag = this.$refs.tagRef 
		new Sortable($tag, {
			group: 'shared',
            animation: 150
		})
	},
	methods: {
		tagClick(tag) {
			this.selectTag.push(tag)
			this[tag.type] = this[tag.type].filter(el => el.label !== tag.label)
		},
		tagClose(tag) {
			this[tag.type].push(tag)
			this.selectTag = this.selectTag.filter(el => el.label !== tag.label)
		}
	}
}
</script>